const validateVerifyCode = (rule, value, callback) => {
    console.log(value);
    if (value === '') {
        callback(new Error('请输入验证码'))
    } else {
        callback()
    }
}

export const rules =  {
    account: [
        { required: true, message: '请输入用户名', trigger: 'blur' },
    ],
    password: [
        { required: true, message: '请输入密码', trigger: 'blur' },
    ],
    verifyCode: [
        { validator: validateVerifyCode, trigger: 'blur', required: true },
    ],

}