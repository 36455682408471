// import http from "@/network";
import http from '@/utils/http';


export const commonLogin = (data) =>
  http.post(
    `/api/v1/common/login`,
      null,
      data
  ); //登录

export const commonUserinfo = (data) =>
  http.get("/api/v1/common/userinfo", data); //获取登录用户信息

export const getVerificationCode = (data) => http.get('/api/v1/common/captcha', data); // 获取验证码
