<template>
  <div class="app-header">
    <div>
      <div class="companyName">
        <el-image :src="require('../../../assets/img/header-logo.png')" />
        <el-tag class="ml-2" type="info">生活缴费管理系统</el-tag>
      </div>
      <div class="menu">
        <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            active-text-color="#fff"
            text-color="#fff"
            background-color="var(--el-color-primary)"
        >
          <template v-for="(item, index) in menuList" :key="index">
            <el-sub-menu
                :index="item.path"
                v-if="item.children && menuKeys.includes(item.roleId)"
                :key="index"
            >
              <template #title>
                <span>{{ item.title }}</span>
              </template>
              <el-menu-item
                  :index="router.path"
                  v-for="(router, i) in item.children"
                  :key="i"
              >
                {{ router.title }}
              </el-menu-item>
            </el-sub-menu>
            <el-menu-item
                :index="item.path"
                v-else-if="!item.children && menuKeys.includes(item.roleId)"
            >
              {{ item.title }}
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="app-user-dropdown">
        <div class="switch" v-if="user.account === '100000'">&nbsp;
          <el-switch
            v-model="switchDevops"
            class="ml-2"
            style="--el-switch-on-color: #0AB2C1;"
            @change="onSwitchChange"
            inline-prompt
            active-text="运维"
            inactive-text="运维"
          />
        </div>
        <div class="app-user">
          <div class="app-avatar">
            <img src="@/assets/img/avatar.png" alt="" />
          </div>
          <div class="user-name">
            {{ userInfo.account }}
          </div>
          <div class="user-name" v-if="userInfo.name">
            ({{ userInfo.name }})
          </div>
          <div class="logout">
            <el-popconfirm title="确定退出登录吗？" @confirm="confirm">
              <template #reference>
                <el-icon><SwitchButton /></el-icon>
              </template>
            </el-popconfirm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, computed } from "vue";
import { ElMessage } from "element-plus";
import { constant } from "../constant";
import { useUserInfoStore } from "@/store/userInfo"
import LocalStore from "@/utils/LocalStore";
import Http from "@/utils/http";
import { useRouter } from "vue-router";

const router = useRouter();

const switchDevops = ref(false);
const user = ref({});
const activeIndex = ref('/changePwd');

const useUserInfo = useUserInfoStore();
let menuKeys = useUserInfoStore().userInfo?.menuKeys || [];
let userInfo = useUserInfo.userInfo;
const emits = defineEmits(["breadcrumb"]);

const confirm = () => {
  useUserInfo.logOut()
}

const getSwitchDevops = () => {
  (async function () {
    const res = await Http.get("/api/v1/common/getDevops");
    switchDevops.value = !res || false;
  })();
};

const onSwitchChange = async () => {
  await Http.get("/api/v1/common/devops");
  ElMessage.success("切换成功");
};

const init = () => {
  const { path } = router.currentRoute.value;
  activeIndex.value = path;
  // handleSelect(path, [meta.openPath, active.value], query, true);
};

const handleSelect = (key, keyPath, query = {}, isAddQuery = false) => {
  let breadcrumb = [];
  let constantItem = constant.find((item) => item.path === keyPath[0]);
  breadcrumb.push(constantItem.title);
  if (keyPath.length > 1) {
    let childrenText =
        constantItem.children?.find((item) => item.path === keyPath[1]) || {};
    childrenText.title && breadcrumb.push(childrenText.title);
  }
  if (isAddQuery === true) {
    router.push({
      path: key,
      query
    });
  } else {
    router.push({
      path: key,
    });
  }
  activeIndex.value = key;
  emits("breadcrumb", breadcrumb);
};

const menuList = computed(() => {
  return constant.filter(item => {
    if (userInfo.account === '100100') {
      if (item.path === '/visitor' || item.path === '/changePwd') return item;
    } else {
      if (item.path !== '/visitor') return item;
    }
  });
});

onMounted(() => {
  init();
  const { userInfo } = LocalStore.get("userInfo") || {};
  if (userInfo) user.value = userInfo, getSwitchDevops();
});
</script>

<style>
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background-color: #22a47e;
}
.el-menu--horizontal .el-menu .el-menu-item.is-active,
.el-menu-item.is-active {
  /*background-color: #018464 !important;*/
  background-color: #22a47e !important;
}
</style>
<style lang="scss" scoped>
.app-user-dropdown {
  display: flex;
  align-items: center;
  .switch {
    margin-right: 10px;
    display: flex;
    align-items: center;
    :deep(.el-switch__core) {
      width: 55px;
    }
  }
}
.companyName {
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #fff;
  display: flex;
  align-items: flex-end;
  //height: 100%;
  .el-image {
    height: 30px;
    filter: grayscale(0.8);
    margin-right: 10px;
  }
  .el-tag {
    background: rgb(13 126 243 / 60%);
    color: #fff;
    border: 1px solid rgb(7, 143, 239);
  }
}
.app-header {
  width: 100%;
  background: var(--el-color-primary);
  position: sticky;
  top: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  color: #fff;
  > div {
    display: flex;
    align-items: center;
    position: relative;
    height: 60px;
    z-index: 10;
    flex-shrink: 0;
    justify-content: space-between;
    padding: 0 20px;

    .menu {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: start;
      align-items: center;
      padding: 0 20px;

      .el-menu {
        width: 100%;
        justify-content: start;
        border: none;
        //background: content-box;
      }
    }
    .el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
      background: none !important;
    }
  }

  .app-user {
    display: flex;
    font-size: 14px;
    align-items: center;
  }
  .app-avatar {
    width: 40px;
    height: 40px;
    line-height: 0;
    margin-right: 10px;
    img {
      width: 40px;
      height: 40px;
    }
  }
}
.logout {
  margin-left: 20px;
  font-size: 20px;
  cursor: pointer;
}
</style>
