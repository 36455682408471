import Axios from "axios";
import qs from "qs";
import { ElMessage } from "element-plus";
import LocalStore from "@/utils/LocalStore";
import { useUserInfoStore } from "@/store/userInfo";

class Http {
  constructor(url, timeout = 2000000) {
    this.server = Axios.create({
      baseURL: url,
      timeout,
    });
    this.userInterceptRequest();
    this.userInterceptResponse();
  }

  userInterceptRequest() {
    this.server.interceptors.request.use(
      (config) => {
        if (!config.params) config.params = {};
        const token = LocalStore.get("token");
        // config.params.token = token || "";
        config.params = {
          token,
          ...config.params,
        };
        return config;
      },
      (error) => {
        console.error(error);
        return Promise.reject(error);
      }
    );
  }

  userInterceptResponse() {
    this.server.interceptors.response.use(
      (response) => {
        let { code, message } = response.data || {};
        // token失效 重新登录
        if (code == "1003") {
          setTimeout(() => {
            let useUserInfo = useUserInfoStore();
            useUserInfo.logOut();
          }, 2000);
          return Promise.reject(new Error(message || "Error"));
        }

        // 对blob类型进行处理
        if (response.config.responseType === "blob") {
          return Promise.resolve(response.data);
        }

        if (code != "0") {
          ElMessage.error(message);
          return Promise.reject(new Error(message || "Error"));
        }
        return Promise.resolve(response.data.body);
      },
      (error) => {
        console.log(error);
        ElMessage({
          type: "error",
          duration: 3000,
          showClose: true,
          message: error.response?.data?.message || "网络错误, 请稍后重试！",
        });
        return Promise.reject(error);
      }
    );
  }

  async get(url, params) {
    const result = await this.server.get(url, { params });
    return result;
  }

  async post(url, data, params) {
    const result = await this.server.post(url, data, { params });
    return result;

  }

  async delete(url, params) {
    const result = await this.server.delete(url, { params });
    return result;
  }

  async upload(url, data, params) {
    const result = await this.server.post(url, data, params);
    return result;
  }

  async downLoad(url, params) {
    const query = qs.stringify(params, { indices: false });
    return await this.server.get(url + '?' + query,{
      responseType: "blob",
    });
  }
}

if (process.env.NODE_ENV === "development") {
  // https://realty.guanfangyiliao.com/ http://192.168.8.145:17000/
  window.baseRequestUrl = "https://realty.guanfangyiliao.com/";
  // window.baseRequestUrl = "http://192.168.8.201:17000";
}

export default new Http(window.baseRequestUrl);
