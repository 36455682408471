import { createRouter, createWebHashHistory } from "vue-router";
import Login from "@/views/login";
import LayOut from "@/components/LayOut";
import LocalStore from "@/utils/LocalStore";
import { useUserInfoStore } from "@/store/userInfo";
export const routes = [
  {
    path: "/login",
    component: Login
  },
  {
    path: "/helpCenter",
    component: () => import('../views/helpCenter/index.vue'),
    meta: {
      title: "帮助中心"
    }
  },
  {
    path: "/",
    component: LayOut,
    children: [
      {
        path: "/houseProperty/houseManagement",
        component: () => import('@/views/houseProperty/houseManagement/index.vue'),
        meta: {
          openPath: "houseProperty",
          title: "房屋管理",
          keepAlive: true
        },
      },
      {
        path: "/houseProperty/houseOwner",
        component: () => import('@/views/houseProperty/houseOwner/index.vue'),
        meta: {
          openPath: "houseProperty",
          title: "业主账户",
        },
      },
      {
        path: "/houseProperty/houseMoneyRecord",
        component: () => import('@/views/houseProperty/houseMoneyRecord/index.vue'),
        meta: {
          openPath: "houseProperty",
          activePath: "/houseProperty/houseOwner",
          title: "充值退费记录",
        },
      },
      {
        path: "/expenseManagement/houseCost",
        name: "ExpenseManagementHouseCost",
        component: () => import('@/views/expenseManagement/houseCost/index.vue'),
        meta: {
          openPath: "expenseManagement",
          title: "房屋费用",
          keepAlive: true
        },
      },
      {
        path: "/expenseManagement/costDetail",
        component: () => import('@/views/expenseManagement/costDetail/index.vue'),
        meta: {
          openPath: "expenseManagement",
          activePath: "/expenseManagement/houseCost",
          title: "退费详情",
        },
      },
      {
        path: "/expenseManagement/billCall",
        component: () => import('@/views/expenseManagement/billCall/index.vue'),
        meta: {
          openPath: "expenseManagement",
          title: "账单催缴",
        },
      },
      {
        path: "/expenseManagement/waterReading",
        component: () => import('@/views/expenseManagement/waterReading/index.vue'),
        meta: {
          openPath: "expenseManagement",
          title: "水表抄表",
        },
      },
      {
        path: "/report/paymentList",
        component: () => import('@/views/report/paymentList/index.vue'),
        meta: {
          openPath: "report",
          title: "缴费清单",
        },
      },
      {
        path: "/report/refundRecord",
        component: () => import('@/views/report/refundRecord/index.vue'),
        meta: {
          openPath: "report",
          title: "退费记录",
        },
      },
      {
        path: "/report/tsmSpringWater",
        component: () => import('@/views/report/tsmSpringWater/index.vue'),
        meta: {
          openPath: "report",
          title: "一卡通充值流水",
        },
      },
      {
        path: "/bill/bank",
        component: () => import('@/views/bill/bank/index.vue'),
        meta: {
          openPath: "bill",
          title: "银行消费"
        }
      },
      {
        path: "/changePwd",
        component: () => import('@/views/changePwd/index.vue'),
        meta: {
          openPath: "/changePwd",
          title: "修改密码",
        },
      },
      {
        path: "/bill/tsmTopUp",
        component: () => import('@/views/bill/tsmTopUp/index.vue'),
        meta: {
          openPath: "bill",
          title: "一卡通充值"
        }
      },
      {
        path: "/bill/tsmConsumption",
        component: () => import('@/views/bill/tsmConsumption/index.vue'),
        meta: {
          openPath: "bill",
          title: "一卡通消费"
        }
      },
      {
        path: "/houseProperty/summaryStatistics",
        component: () => import('@/views/houseProperty/summaryStatistics/index.vue'),
        meta: {
          openPath: "houseProperty",
          title: "房屋汇总"
        }
      },
      {
        path: "/houseProperty/houseMember",
        component: () => import('@/views/houseProperty/houseMember/index.vue'),
        meta: {
          openPath: "houseProperty",
          title: "成员管理",
        },
      },
      {
        path: "/houseProperty/houseDetailed",
        component: () => import('@/views/houseProperty/houseDetailed/index.vue'),
        meta: {
          openPath: "houseProperty",
          title: "房屋入/退住记录"
        }
      },
      {
        path: "/bill/feeRecords",
        component: () => import('@/views/bill/feeRecords/index'),
        meta: {
          openPath: "bill",
          title: "收费记录"
        }
      },
      {
        path: "/visitor",
        component: () => import('@/views/visitor/index'),
        meta: {
          openPath: "visitor",
          title: "访客设置"
        }
      }
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

let obj = {
  "001": {
    activePath: "/houseProperty/houseManagement",
    openPath: "houseProperty",
  },
  "002": {
    activePath: "/expenseManagement/houseCost",
    openPath: "expenseManagement",
  },
  "003": {
    activePath: "/report/paymentList",
    openPath: "report",
  },
  "004": {
    activePath: "/changePwd",
    openPath: "/changePwd",
  },
};


router.beforeEach((to, from, next) => {
  if (to.path === "/helpCenter") {
    next();
  }
  if (LocalStore.get("token")) {
    if (to.path === "/") {
      let menuKeys = useUserInfoStore().userInfo?.menuKeys || [];
      let activePath = obj[menuKeys[0]]?.activePath || "/login";
      router.replace({ path: activePath });
    }
    next();
  } else {
    if (to.path === "/login") {
      next();
    } else {
      next({ path: "/login", replace: true }); // 否则全部重定向到登录页
    }
    // 没有token
  }
});

export default router;
