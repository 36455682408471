<template>
  <div class="login">
    <div class="login-box">
      <div class="login-banner">
        <img :src="require('@/assets/img/banner.png')" alt="" />
      </div>
      <div class="login-form">
        <h3>生活缴费</h3>
        <el-form ref="ruleFormRef" :model="state" :rules="rules" status-icon>
          <el-row>
            <el-col :span="24">
              <el-form-item label="账号" prop="account">
                <el-input
                    v-model="state.account"
                    placeholder="请输入用户名"
                    @keyup.enter="submit(ruleFormRef)"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="密码" prop="password">
                <el-input
                    :show-password="true"
                    placeholder="请输入密码"
                    v-model="state.password"
                    @keyup.enter="submit(ruleFormRef)"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="24">
              <el-form-item label="验证码" prop="verifyCode">
                <el-input
                    style="flex: 1"
                    placeholder="请输入验证码"
                    v-model="state.captcha"
                    @input="onCaptchaInput"
                    @keyup.enter="submit(ruleFormRef)"
                />
                <img :src="verificationCodeImg" :alt="verificationCodeImg" @click="verificationCode" />
                <span v-if="codeOverdue" class="code-overdue" @click="verificationCode">已过期</span>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="btn">
            <el-button type="primary" :loading="loading" @click="submit(ruleFormRef)">
              登录
            </el-button>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script setup>
import md5 from "js-md5";
import {onMounted, reactive, ref} from "vue";
import {rules} from "./constant";
import {useRouter} from "vue-router";
import LocalStore from "@/utils/LocalStore";
import {useUserInfoStore} from "@/store/userInfo";
import {commonLogin, commonUserinfo, getVerificationCode} from "@/api/login";
import generateUUID from "@/utils/utils/generateUUID";

const router = useRouter();
const useUserInfo = useUserInfoStore();
let uid = '';
let timer = null;
const ruleFormRef = ref(null);
let loading = ref(false);
let state = reactive({
  account: "",
  password: "",
  captcha: "",
});
const verificationCodeImg = ref('');
const codeOverdue = ref(false);

const verificationCode = async () => {
  uid = generateUUID();
  codeOverdue.value = false;
  verificationCodeImg.value = await getVerificationCode({uid});
  if (timer) clearTimeout(timer);
  timer = setTimeout(() => {
    codeOverdue.value = true;
  }, 60 * 1000);
};

const submit = async (formEl) => {
  if (!formEl) return;
  formEl.validate(async (valid, fields) => {
    if (valid) {
      loading.value = true
      try {
        let data = {
          ...state,
          password: md5(state.password),
          uid,
        };
        let res = await commonLogin(data);
        if (res) {
          LocalStore.set("token", res);
          let userInfo = await commonUserinfo({ token: res });
          await useUserInfo.setUsrInfo(userInfo);
          loading.value = false;
          if (state.account === '100100') {
            return router.push('/visitor');
          }
          router.push("/");
        }
      } catch (error) {
        loading.value = false;
        verificationCode();
      }
      
    } else {
      console.log("error submit!", fields);
    }
  });
};

const onCaptchaInput = (value) => {
  // console.log(value);
  state.captcha = value;
};

onMounted(() => {
  verificationCode();
})

</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  background-image: url("../../assets/img/bg.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  .login-box {
    width: 680px;
    height: 400px;
    background: #ffffff;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    border-radius: 22px;
    display: flex;
  }
  .login-form {
    //margin: 60px 60px 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 25px 40px;
    //width: 100%;
    h3 {
      text-align: center;
      margin-top: 0;
      font-size: 24px;
      margin-bottom: 10px;
    }
    .el-form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .el-form-item {
        display: block;
        :deep(.el-input__wrapper) {
          border-bottom: 1px solid #e6e6e6;
          box-shadow: none;
          border-radius: 0;
        }
      }
    }
    img {
      height: 40px;
      width: 80px;
      cursor: pointer;
    }
    .code-overdue {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 80px;
      background: rgba(0, 0, 0, 0.3);
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .btn {
    width: 100%;
    .el-button {
      width: 100%;
      height: 40px;
      margin-top: 10px;
      background: linear-gradient(0deg, #018464 0%, #1eb590 100%);
      border-radius: 20px;
    }
  }
  .login-banner {
    //width: 340px;
    height: 400px;
    flex: 1;
    >img {
      display: block;
      width: 360px;
      height: 400px;
    }
  }
}
</style>
