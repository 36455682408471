import { createApp } from "vue";
import ElementPlus from "element-plus";

// import "element-plus/dist/index.css";
import "./assets/css/variables.scss";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import App from "./App.vue";
import router from "./router";
import pinia from "@/store/index.js";

import './utils/rem'
import directives from "./directive";

const app = createApp(App);

// console.log(directives);
Object.entries(directives).forEach(([key, value]) => {
  app.directive(key, value);
});

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.use(ElementPlus, {
  locale: zhCn,
}).use(router).use(pinia).mount("#app");
