const findEle = (parent, type) => {
  return parent.tagName.toLowerCase() === type ? parent : parent.querySelector(type)
};
const emoji = {
  mounted: function (el) {
    // 正则规则可根据需求自定义
    const regRule =  /[^\d]/g;
    const $inp  = findEle(el,"input");
    el.$inp = function () {
      let val = $inp.value;
      $inp.value = val.replace(regRule,"");
    }
    if ($inp) $inp.addEventListener("keyup", el.$inp);
  },
  unmounted: function (el) {
    el.$inp.removeEventListener('keyup', el.$inp)
  },
}

export default emoji