<template>
  <div class="app">
    <router-view />
  </div>
</template>

<style lang="scss" >
body {
  margin: 0;
}
html,
body,
#app,
.app {
  width: 100%;
	height: 100%;
	background: #F0F2F5;
}
</style>
