<template>
  <div class="layOut">
<!--    <SideMenu @breadcrumb="breadcrumb"></SideMenu>-->
    <div class="header-main">
        <Header @breadcrumb="breadcrumb"></Header>
        <Main :breadcrumbList="breadcrumbList"></Main>
    </div>
  </div>
</template>


<script setup>
// import SideMenu from "./SideMenu"
import Main from "./Main";
import Header from "./Header";
import { ref } from "vue";
let breadcrumbList = ref([]);


const breadcrumb = (list) => {
  breadcrumbList.value = list;
}
</script>

<style lang="scss" scoped>
.layOut{
  display: flex;
  height: 100%;
    /* background-color: #D6D6D6; */
}
.header-main {
  //width: calc(100vw - 200px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  >div {
    //flex: 1;
    width: 100%;
  }
}
</style>
