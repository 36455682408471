const keyBoard = (e, fc) => {
    if (e.code === "Enter") {
        fc();
    }
};

const keyDownSearch = {
    mounted: function (el, binding) {
        // 正则规则可根据需求自定义
        el.addEventListener("keydown", (e) => keyBoard(e, binding.value));
    },
    unmounted: function (el) {
        el.removeEventListener('keyup', keyBoard);
    },
}

export default keyDownSearch;