function generateUUID() {
    // 创建 UUID 的各部分
    const template = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

    // 替换模板中的 x 和 y
    return template.replace(/[xy]/g, function (c) {
        const random = Math.random() * 16 | 0;
        const value = c === 'x' ? random : (random & 0x3 | 0x8);
        return value.toString(16);
    });
}

export default generateUUID;
