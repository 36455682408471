export const constant = [
  {
    roleId: "001",
    title: "房产管理",
    path: "houseProperty",
    children: [
      {
        title: "房屋管理",
        path: "/houseProperty/houseManagement",
      },
      {
        title: "业主管理",
        path: "/houseProperty/houseOwner",
      },
      {
        title: "房屋入退记录",
        path: "/houseProperty/summaryStatistics"
      }
    ],
  },
  {
    roleId: "002",
    title: "费用管理",
    path: "expenseManagement",
    children: [
      {
        title: "房屋收费",
        path: "/expenseManagement/houseCost",
      },
      {
        title: "账单催缴",
        path: "/expenseManagement/billCall",
      },
      {
        title: "水表抄表",
        path: "/expenseManagement/waterReading",
      },
    ],
  },
  {
    roleId: "003",
    title: "报表管理",
    path: "report",
    children: [
      {
        title: "缴费清单",
        path: "/report/paymentList",
      },
      {
        title: "业主余额变动记录",
        path: "/report/refundRecord",
      },
      {
        title: "一卡通充值流水",
        path: "/report/tsmSpringWater",
      },
    ],
  },
  {
    roleId: "003",
    title: "账单核对",
    path: "bill",
    children: [
      {
        title: "生活消费对账",
        path: "/bill/bank",
      },
      // {
      //   title: "一卡通消费",
      //   path: "/bill/tsmConsumption"
      // },
      {
        title: "一卡通充值对账",
        path: "/bill/tsmTopUp"
      },
      {
        title: "物业/房租月清单",
        path: "/bill/feeRecords"
      },
    ],
  },
  {
    roleId: "004",
    title: "访客设置",
    path: "/visitor",
  },
  {
    roleId: "004",
    title: "修改密码",
    path: "/changePwd",
  },

];
