const store = window.localStorage;

class LocalStore {
  static set(key, value) {
    if (!store) return;
    const v = value;
    try {
      if (typeof v === "string") {
        store.setItem(key, v);
      } else {
        store.setItem(key, JSON.stringify(v));
      }
    } catch (e) {
      console.log(e);
    }
  }

  static get(key) {
    if (!store) return;
    const data = store.getItem(key);
    try {
      if (data) {
        try {
          return JSON.parse(data);
        } catch (e) {
          return data;
        }
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  static remove(key) {
    if (!store) return;
    try {
      store.removeItem(key);
    } catch (e) {
      console.log(e);
    }
  }
}

export default LocalStore;
