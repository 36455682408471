import { defineStore } from "pinia";
// import { commonLogout } from "@/api/common";
const initState = {
  userInfo: {},
};

export const useUserInfoStore = defineStore("userInfo", {
  state: () => initState,
  actions: {
    setUsrInfo(playLoad) {
      this.userInfo = playLoad;
    },
    async logOut() {
      this.userInfo = {};
      // await commonLogout();
      window.localStorage.clear();
      window.location.replace(`/#/login`);
    },
  },
  persist: true, // 开启数据缓存
});
